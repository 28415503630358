
const ACCOUNT_INFO = "account_info" as string;

/**
 * @description get token form localStorage
 */
 export const getAccountInfo = ():string|any=> {
     return window.localStorage.getItem(ACCOUNT_INFO);

  };

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveAccountInfo = (accountInfo: any): void => {
  window.localStorage.setItem(ACCOUNT_INFO,  JSON.stringify(accountInfo));
};

/**
 * @description remove token form localStorage
 */
export const destroyAccountInfo = (): void => {
  window.localStorage.removeItem(ACCOUNT_INFO);
};

export default { getAccountInfo, saveAccountInfo, destroyAccountInfo };
