import { createApp } from "vue";
import App from "./App.vue";
import Paginate from "vuejs-paginate-next";
import "vue-select/dist/vue-select.css";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import vSelect from "vue-select";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from '@vuepic/vue-datepicker';
import "@/core/plugins/prismjs";
import "bootstrap";
window._ = require("lodash");
import "@/core/plugins/prismjs";
import "bootstrap";
import globalGonfigiration from '@/includes/_globals';

const app = createApp(App);
app.component("v-select", vSelect);
app.component('Datepicker', Datepicker);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Paginate);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(globalGonfigiration);
app.use(i18n);

app.mount("#app");
