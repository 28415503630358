import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import UserService from "@/core/services/UserService";
import SettingsService from "@/core/services/SettingsService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { da } from "date-fns/locale";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Permissions {
  stock: boolean;
  price:  boolean;
  orders:  boolean;
  deliveries:  boolean;
  invoices: boolean;
  credits:  boolean;
  payments: boolean;
}
export interface SettingsInfo {
  permissions: Permissions;
}

@Module
export default class SettingsModule extends VuexModule implements SettingsInfo {
  permissions = {} as Permissions; 
  settings = JSON.parse(SettingsService.getSettings())||{};

  /**
   * Get permissions
   * @returns array
   */
  get getSettingsData() {
    return this.settings;
  }

  @Mutation
  [Mutations.SET_SETTINGS](payload) {
    this.settings = payload;
  }

  @Action
  [Actions.GET_SETTINGS]() {
    return ApiService.get("settings")
      .then(({ data }) => {
        SettingsService.saveSettings(data);
        this.context.commit(Mutations.SET_SETTINGS,data );
      })
      .catch(({ response }) => {
        console.log("Error", response);
      });
  }


}
