import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
      {
        path: "/deliveries",
        name: "deliveries",
        component: () => import("@/views/documents/Deliveries.vue"),
      },
      {
        path: "/estimates",
        name: "estimates",
        component: () => import("@/views/documents/Estimates.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/documents/Orders.vue"),
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/documents/Invoices.vue"),
      },
      {
        path: "/payments",
        name: "payments",
        component: () => import("@/views/payments/Payments.vue"),
      },
      {
        path: "/password-change",
        name: "password-change",
        component: () =>
          import("@/views/auth/ChangePassword.vue"),
      },
      {
        path: "/items",
        name: "items",
        component: () =>
          import("@/views/items/ItemsListing.vue"),
      },
      {
        path: "/preorders",
        name: "preorders",
        component: () => import("@/views/preorders/Preorders.vue"),
      },
      {
        path: "/preorders/create",
        name: "makePreOrder",
        component: () =>
          import("@/views/preorders/MakePreOrder.vue"),
      },
      {
        path: "/preorders/:id",
        name: "preorder",
        component: () => import("@/views/preorders/Preorder.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/auth/LogIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/auth/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
router.beforeEach(async (to, from) => {
  if (
    store.getters.isUserAuthenticated &&
    to.name == 'sign-in'
  ) {
    // redirect the user to the login page
    return { name: 'dashboard' }
  }
  if (
    store.getters.isUserAuthenticated &&
    to.name == 'password-reset'
  ) {
    // redirect the user to the login page
    return { name: '404' }
  }
})


// Permissions Gurads
router.beforeEach((to, from) => {
  const permissions = store.getters.getSettingsData.permissions;
  if (to.name == "deliveries" && !permissions.delivery) {
    return { name: "404" };
  }
  if (to.name == "orders" && !permissions.sorder) {
    return { name: "404" };
  }
  if (to.name == "invoices" && !permissions.invoice) {
    return { name: "404" };
  }
  if (to.name == "payments" && !permissions.payments) {
    return { name: "404" };
  }
});
export default router;
