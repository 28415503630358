import { App, Plugin } from 'vue';
import _ from "lodash";

const GlobalComponentGonfigue: Plugin = {
  install(app: App) {
    let baseComponents = require.context("../components/base/", false, /[A-Za-z0-9-_,\s]+\.vue$/i);
    baseComponents.keys().forEach((fileName) => {
      const componentConfig = baseComponents(fileName);
      const componentName = _.upperFirst(
        _.camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")),
      );
      app.component(`Base${componentName}`, componentConfig.default || componentConfig);
    });
  },
};
export default GlobalComponentGonfigue;