
const SETTINGS = "settings" as string;

/**
 * @description get token form localStorage
 */
 export const getSettings= ():string|any=> {
     return window.localStorage.getItem('settings');

  };

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveSettings = (data: any): void => {
  window.localStorage.setItem(SETTINGS,  JSON.stringify(data));
};

/**
 * @description remove token form localStorage
 */
export const destroySettings = (): void => {
  window.localStorage.removeItem(SETTINGS);
};

export default { getSettings, saveSettings, destroySettings };
