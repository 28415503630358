<template>
  <div
    class="alert alert-dismissible bg-warning d-flex flex-column flex-sm-row w-100 p-5 mb-10"
  >
    <span class="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
      <inline-svg src="media/icons/duotune/general/gen007.svg" />
    </span>
    <div class="d-flex flex-column text-light pe-0 pe-sm-10">
      <span>Aucun résultat trouvé</span>
    </div>
  </div>
</template>
