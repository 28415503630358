import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class ItemsModule extends VuexModule {
  errors = {};
  checkedItems = [];
  items=[];
  get CurrentCheckedItems() {
    return this.checkedItems;
  }
  get allItems() {
    return this.items;
  }
  @Mutation
  [Mutations.SET_CHECKED_ITEMS](items) {
    this.checkedItems =items;
  }
  @Mutation
  [Mutations.SET_ITEMS](items) {
    this.items =items;
  }
  @Action
  [Actions.GET_ALL_ITEMS]() {
    return ApiService.get("items")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ITEMS, data);
      })
      .catch(({ response }) => {
        console.log("Error", response);
      });
  }
}
